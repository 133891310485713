import { React, useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import "./comonUtils.css"

const aws_cloudfront_domain_name = "https://d1nqkkjom3zmdm.cloudfront.net";
const aws_s3_domain_name = "https://ch-website-images.s3.us-west-1.amazonaws.com"

function ReadMore(props) {
    var title = "Read More";
    var display = "flex";
    var linkClassName = 'read-more-link'
    var relativePathSep = '/'
    const queryString = props.artistKey ? `?artistKey=${props.artistKey}` : "";
    if (props.isAbsolutePath) {
        relativePathSep = ''
    }
    if (props.title) {
        title = props.title;
    }
    if (props.hide) {
        display = "none"
    }
    if (props.linkClassName) {
        linkClassName = props.linkClassName
    }
    return (
        <div className={props.className} style={{display: display, justifyContent: "center" }}>
            <Link
                className={linkClassName}
                to={relativePathSep + `${props.page}` + queryString}
                state={props.params}
            >
                { title }
            </Link>
            <NavigateNextIcon
                fontSize='large'
            />
        </div>
    )
}

function ReadLess(props) {
    return (
        <div>
            <div className='add-padding-bottom' style={{display: "flex", justifyContent: "center" }}>
                <NavigateBeforeIcon
                    fontSize='large'
                />
                <Link
                    className='read-more-link'
                    to={`/${props.page}`}
                >
                    Read Less
                </Link>
            </div>
        </div>
    )
}

const GetS3CloudFrontJson = (props) => {
    const [json, setJson] = useState({});

    async function fetchData() {
        const response = await fetch(props.filePath);
        setJson(await response.json());
      }

      useEffect(() => {
        fetchData();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []);

      return json
}

export {
    ReadLess,
    ReadMore,
    aws_cloudfront_domain_name,
    aws_s3_domain_name,
    GetS3CloudFrontJson
}