import React from 'react';
import Stack from '@mui/material/Stack';
import Header from "../navigation/Header"
import Footer from "../footer/Footer"
import { ReadMore, aws_cloudfront_domain_name, GetS3CloudFrontJson } from '../../common/commonUtils';
import "./HairExtensions.css"

function extractHairExtensionsInfo() {
    const s3Json = GetS3CloudFrontJson({filePath: "https://d3bt1m4w3iifww.cloudfront.net/hairExtensions.json"})

    var allInfo = {
        "title": "",
        "intro": {},
        "section1": {},
        "section2": {},
        "section3": {}
    }

    for (let key in s3Json) {
        if (key in allInfo) {
            allInfo[key] = s3Json[key]
        }
    }

    var title = ""
    var intro = ""
    var section1 = ""
    var section2 = ""
    var section3 = ""

    if (allInfo["title"]) {
        title = allInfo["title"]
    }
    if (allInfo["intro"]) {
        intro = JSON.stringify(allInfo["intro"])
    }
    if (allInfo["section1"]) {
        section1 = JSON.stringify(allInfo["section1"])
    }
    if (allInfo["section2"]) {
        section2 = JSON.stringify(allInfo["section2"])
    }
    if (allInfo["section3"]) {
        section3 = JSON.stringify(allInfo["section3"])
    }
    
    return {
        "title": title,
        "intro": intro,
        "section1": section1,
        "section2": section2,
        "section3": section3
    }

}

const images = {
    ExtensionConsultation: "extension-consultation-title-banner",
    MaintenanceAppointments: "maintenance-appointments-title-banner",
    NewGuests: "new-guests-title-banner",
    HandTiedExtensions: aws_cloudfront_domain_name + "/hairExtensions/hand_tied_extensions.png"
}

function Service(props) {
    return (
        <Stack direction='row'>
            <div className='he-service-title'>
                { props.name }
            </div>
            <div className='he-service-time'>
                { props.time }
            </div>
        </Stack>
    )
}

function ServiceDescription(props) {
    return (
        <div className='service-description'>
            { props.text }
        </div>
    )
}

function Note(props) {
    return (
        <div className='note-container'>
            { props.text }
        </div>
    )
}

function HairExtensionsTitle(props) {
    return (
        <div>
            <div className='hair-extensions-title'>
                { props.title }
            </div>
        </div>
    )
}

function IntroTextBody(props) {
    const textBody = JSON.parse(props.textBody)
    return (
        <div className="intro-text-body">
            { textBody.open }
            <br/>
            { textBody.bridge }
            <br/><br/>
            { textBody.close }
        </div>
    )
}

function TitleAndBanner(props) {
    return (
        <div className={props.className}>
            <div className="title-banner">
                { props.title }
            </div>
        </div>
    )
}

function ExtensionConsultationBox(props) {
    const textBody = JSON.parse(props.textBody)
    const service1 = textBody.service1 ? textBody.service1 : {}
    var serviceText = null
    var note = null
    var readMore = <div><br/><ReadMore page="extensionConsultation"/></div>
    if (props.isDesktop) {
        serviceText = <ServiceDescription text={textBody.paragraph1}/>
        note = <Note text={textBody.note}/>
        readMore= null
    }
    
    return (
        <div>
            <TitleAndBanner
                className={images.ExtensionConsultation}
                title={textBody.title}
            />
            <br/>
            <Service
                name={service1.name}
                time={service1.time}
            />
            { serviceText }
            { note }
            { readMore }
        </div>
    )
}

function MaintenanceAppointmentsBox(props) {
    const textBody = JSON.parse(props.textBody)
    const service1 = textBody.service1 ? textBody.service1 : {}
    const service2 = textBody.service2 ? textBody.service2 : {} 
    var serviceText = null
    var readMore = <div><br/><ReadMore page="maintenanceAppointments"/></div>
    if (props.isDesktop) {
        serviceText = 
        <div>
            <ServiceDescription
                text={textBody.paragraph1}
            />
            <ServiceDescription
                text={textBody.paragraph2}
            />
            <ServiceDescription
                text={textBody.paragraph3}
            />
            <br/>
        </div>
        readMore = null
    }
    
    return (
        <div>
            <TitleAndBanner
                className={images.MaintenanceAppointments}
                title={ textBody.title }
            />
            <br/>
            { serviceText }
            <Service
                name={ service1.name }
                time={ service1.time }
            />
            <Service
                name={ service2.name }
                time={ service2.time }
            />
            { readMore }
        </div>
    )
}

function NewGuestsBox(props) {
    const textBody = JSON.parse(props.textBody)
    const service1 = textBody.service1 ? textBody.service1 : {}
    const service2 = textBody.service2 ? textBody.service2 : {} 
    const service3 = textBody.service3 ? textBody.service3 : {}
    var serviceText = null
    var note = null
    var readMore = <div><br/><ReadMore page="newGuests"/></div>
    if (props.isDesktop) {
        serviceText = <div>
                            <br/>
                            <ServiceDescription text={textBody.paragraph1}/>
                        </div>

        note = <div>
                    <br/>
                    <Note text={textBody.note}/>
                </div>
        readMore = null
    }
    
    return(
        <div>
            <TitleAndBanner
                className={images.NewGuests}
                title="New Guests"
            />
            { serviceText }
            { note }
            <br/>
            <Service
                name={service1.name}
                time={service1.time}
            />
            <Service
                name={service2.name}
                time={service2.time}
            />
            <br/>
            <Service
                name={service3.name}
                time={service3.time}
            />
            <br/>
            <Note
                text={ textBody.disclaimer}
            />
            { readMore }
        </div>
    )
}

function DesktopView(props) {
    return (
        <div className='desktop-view-hair-extensions'>
            <HairExtensionsTitle
                title={props.hairExtensionInfo.title}
            />
            <IntroTextBody
                textBody={props.hairExtensionInfo.intro}
            />
            <ExtensionConsultationBox
                isDesktop={true}
                textBody={props.hairExtensionInfo.section1}
            />
            <MaintenanceAppointmentsBox
                isDesktop={true}
                textBody={props.hairExtensionInfo.section2}
            />
            <NewGuestsBox
                isDesktop={true}
                textBody={props.hairExtensionInfo.section3}
            />
            <img
                className='hand-tied-extension-img'
                src={images.HandTiedExtensions}
                alt=""
            />
        </div>
    )
}

function MobileView(props) {
    return (
        <div className='mobile-view-hair-extensions'>
            <HairExtensionsTitle
                title={props.hairExtensionInfo.title}
            />
            <IntroTextBody
                textBody={props.hairExtensionInfo.intro}
            />
            <ExtensionConsultationBox
                isDesktop={false}
                textBody={props.hairExtensionInfo.section1}
            />

            <MaintenanceAppointmentsBox
                isDesktop={false}
                textBody={props.hairExtensionInfo.section2}
            />
            <NewGuestsBox
                isDesktop={false}
                textBody={props.hairExtensionInfo.section3}
            />
            <img
                className='hand-tied-extension-img'
                src={images.HandTiedExtensions}
                alt=""
            />
        </div>
    )
}

function HairExtensions() {
    const info = extractHairExtensionsInfo()
    return (
        <div className='hair-extensions-container'>
            <Header/>
            <DesktopView
                hairExtensionInfo={info}
            />
            <MobileView
                hairExtensionInfo={info}
            />
            <Footer/>
        </div>
    )
}

export {
    HairExtensions,
    ExtensionConsultationBox,
    MaintenanceAppointmentsBox,
    NewGuestsBox,
    extractHairExtensionsInfo
}