import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css'
import { Menu, MenuItem, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { aws_cloudfront_domain_name } from '../../common/commonUtils';

function validServicesExtensions() {
  return (
    new Set(["lightening", "customColoring", "haircut", "canvas", "blowout"])
  )
}

function validBrandsExtensions() {
  return (
    new Set(["productOne", "productTwo"])
  )
}

function validHairExtensionsExtensions() {
  return (
    new Set(["extensionConsultation", "maintenanceAppointments", "newGuests"])
  )
}

function validMeetTheArtistPage() {
  return (
    new Set(["artistPage"])
  )
}

function getUrlExtension() {
  return (
    window.location.href
    .split("?")[0]
    .split("/").slice(-1)[0]
  )
}

function HeaderLogo() {
  
  return (
        <img className='header-logo' src={aws_cloudfront_domain_name + "/logosAndIcons/ch_logo.png"}
            alt=""
            width={138}
            height={75}/>
  )
}

function HamburgerDropDown() {

  const [anchor, setAnchor] = useState(null);

  const openMenu = (event) => {
    setAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchor(null);
  };

  var icon = (
                <IconButton onClick={openMenu}>
                  <MenuIcon/>
                </IconButton>
      )

  if (validServicesExtensions().has(getUrlExtension())) {
      icon = (
                <NavLink to="/services" exact style={{color: "grey"}}>
                  <ArrowBackIosNewIcon/>
                </NavLink>
      ) 
    }
    else if (validBrandsExtensions().has(getUrlExtension())) {
      icon = (
                <NavLink to="/brands" exact style={{color: "grey"}}>
                  <ArrowBackIosNewIcon/>
                </NavLink>
      )
    }
    else if (validHairExtensionsExtensions().has(getUrlExtension())) {
      icon = (
                <NavLink to="/hairExtensions" exact style={{color: "grey"}}>
                  <ArrowBackIosNewIcon/>
                </NavLink>
      )
    }
    else if (validMeetTheArtistPage().has(getUrlExtension())) {
      icon = (
                <NavLink to="/team" exact style={{color: "grey"}}>
                  <ArrowBackIosNewIcon/>
                </NavLink>
      )
    }

  return (
          <div className='hamburger-drop-down'>
            {
              icon
            }
            <Menu
              open={Boolean(anchor)}
              anchorEl={anchor}
              onClose={closeMenu}
              keepMounted
            >
            <MenuItem className='short-menu-item'>
              <NavLink to="/services" exact>
                Signature Services
              </NavLink>
            </MenuItem>
            <MenuItem className='short-menu-item'>
              <NavLink to="/hairExtensions" exact>
                Hair Extensions
              </NavLink>
            </MenuItem>
            <MenuItem className='short-menu-item'> 
              <NavLink to="/brands" exact>
                Luxury Brands
              </NavLink>
            </MenuItem>
            <MenuItem className='short-menu-item'> 
              <NavLink to="/team" exact>
                Meet the Artists
              </NavLink>
            </MenuItem>
            </Menu>
          </div>
        )
}

export default function Header() {

  const servicesReadMore = validServicesExtensions().has(getUrlExtension())
  const brandsReadMore = validBrandsExtensions().has(getUrlExtension())
  const hairExtensionsReadMore = validHairExtensionsExtensions().has(getUrlExtension())

  
  return (
    <nav className='header'>
      <div className='header-container'>
        <HamburgerDropDown/>
        <div className='long-menu-header-links'>
          <ul>
            <li>
              <NavLink to="/services" exact
                className={({ isActive }) => 
                  isActive || servicesReadMore ? 'highlight-tab' : 'non-highlight-tab'
                }
              >
                Signature Services
              </NavLink>
            </li>
            <li>
              <NavLink to="/hairExtensions" exact
                className={({ isActive }) => 
                  isActive || hairExtensionsReadMore ? 'highlight-tab' : 'non-highlight-tab'
                }
              >
                Hair Extensions
              </NavLink>
            </li>
            <NavLink to="/" exact>
              <HeaderLogo/>
            </NavLink>
            <li>
              <NavLink to="/brands" exact
                className={({ isActive }) => 
                  isActive || brandsReadMore ? 'highlight-tab' : 'non-highlight-tab'
                }
              >
                Luxury Brands
              </NavLink>
            </li>
            <li>
              <NavLink to="/team" exact
                className={({ isActive }) => 
                  isActive ? 'highlight-tab' : 'non-highlight-tab'
                }
              >
                Meet the Artists
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
)
}