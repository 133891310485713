import React from 'react';
import { CustomColoringServiceBox, Disclaimer, ServicesTitle, extractServiceInfo } from "../Services";
import { ReadLess } from '../../../common/commonUtils';
import Header from '../../navigation/Header';
import Footer from '../../footer/Footer';
import Stack from '@mui/material/Stack';
import "../Services.css"

function Body() {
    const serviceInfo = extractServiceInfo()
    return (
        <div className='mobile-view'>
            <ServicesTitle/>
            <div className='stack-row-container-read-more'>
                <Stack direction='column'>
                    <CustomColoringServiceBox
                        hideDescriptions={false}
                        desktopView={true}
                        customColoringInfo={serviceInfo.customColoringInfo}
                    />
                    <Disclaimer
                        bodyText={serviceInfo.disclaimer}
                    />
                </Stack>
            </div>
            <ReadLess
                page="services"
            />
        </div>
    )
}

export default function CustomColoring() {
    return (
        <div className='services-container'>
        <Header/>
        <Body/>
        <Footer/>
        </div>
    )
}