import React from 'react';
import Header from '../../navigation/Header';
import Footer from '../../footer/Footer';
import { BrandsTitle, ProductTwoHeaderAndImage, ProductTwoDescriptionBox, ProductTwoExtraImages, ProductTwoHairRegrowthHeaderandImage, extractBrandsInfo } from '../Brands';
import { ReadLess } from '../../../common/commonUtils';
import "../Brands.css"

export default function ProductTwo() {
    const info = extractBrandsInfo()
    const product = info.product2 ? JSON.parse(info.product2) : {}
    return (
        <div className='brands-container'>
            <Header/>
            <div className='mobile-view'>
                <BrandsTitle/>
                <ProductTwoHeaderAndImage
                    title={product.title}
                />
                <ProductTwoDescriptionBox
                    info={JSON.stringify(product.brandBox1)}
                />
                <ProductTwoExtraImages/>
                <ProductTwoHairRegrowthHeaderandImage
                    info={JSON.stringify(product.brandBox2)}
                />
                <ReadLess
                    page="brands"
                />
            </div>
            <Footer/>
        </div>
    )
}