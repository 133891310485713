import React from 'react';
import Stack from '@mui/material/Stack';
import Header from "../navigation/Header"
import Footer from "../footer/Footer"
import { ReadMore, GetS3CloudFrontJson, aws_cloudfront_domain_name, aws_s3_domain_name } from '../../common/commonUtils';
import BookAppointmentBuyGiftCard from '../embedded/BookAppointmentBuyGiftCard';
import "./LandingPage.css"

function extractHomepageInfo() {
    const s3Json = GetS3CloudFrontJson({filePath: "https://d1zvvta1i6zmb3.cloudfront.net/homepage.json"})

    var allInfo = {
        "title": {},
        "welcomeMessage": {}
    }

    for (let key in s3Json) {
        if (key in allInfo) {
            allInfo[key] = s3Json[key]
        }
    }

    var title = ""
    var welcomeMessage = ""

    if (allInfo["title"]) {
        title = allInfo["title"]
    }
    if (allInfo["welcomeMessage"]) {
        welcomeMessage = allInfo["welcomeMessage"]
    }

    return {
        "title": title,
        "welcomeMessage": welcomeMessage
    }

}

function Title(props) {
    return (
        <div>
            <h1 className={props.className}>
                <div className={props.isDesktop ? 'hide' : ''}>
                        { props.titleInfo.line1 }
                    <br/>
                        { props.titleInfo.line2 }
                </div>
                <div className={props.isDesktop ? '' : 'hide'}>
                    { props.titleInfo.line1 + " " + props.titleInfo.line2 }
                </div>
            </h1>
        </div>
    )
}

function WelcomeContainer(props) {
    const welcomeMessageInfo = props.welcomeMessageInfo

    return (
        <div className={props.className}>
            <p class="welcome-message-text-landing-page">
                <div className='welcome-message-header-text-landing-page'>
                    { welcomeMessageInfo.title }
                </div>
                <br/>
                <div className='welcome-message-sub-header-text-landing-page'>
                    { welcomeMessageInfo.header1 }
                </div>
                <br/>
                    { welcomeMessageInfo.body1 }
                <br/><br/>
                <div className='bold'>{ props.welcomeMessageInfo.header2 }</div>
                    { welcomeMessageInfo.body2}
                <br/><br/>
                <div className='welcome-message-sub-header-text-landing-page'>
                    { welcomeMessageInfo.header3 }
                </div>
                <br/>
                    { welcomeMessageInfo.body3 }
                <br/>
                    { welcomeMessageInfo.body4 }
                <br/><br/>
                <div className='welcome-message-sub-header-text-landing-page'>
                    { welcomeMessageInfo.outro }
                </div>
            </p>
        </div>
    )
}

function SubImagesAndTheirLinks(props) {
    function SubImageLink(props) {
        var className = 'sub-image-link-landing-page-single'
        switch (props.position) {
            case 'double':
                className = 'sub-image-link-landing-page-top'
                break;
            case 'single':
                className = 'sub-image-link-landing-page-single'
                break;
            case 'bottom':
            default:
                className = 'sub-image-link-landing-page-bottom'
                break;
        }
        return (
            <div
                className={className}
            >
                <ReadMore
                    linkClassName={'read-more-link-landing-page'}
                    title={props.title}
                    page={props.page}
                />
            </div>
        )
    }
    return (
        <div>
        <Stack direction={props.isDesktop ? "row" : "column"}>
            <div
                className='sub-image-landing-page1'
            >
                    <img
                        src={aws_cloudfront_domain_name + "/homePage/LandingPageImage1.jpg"}
                        className='image-styling'
                        alt={aws_s3_domain_name + "/homePage/LandingPageImage1.jpg"}
                        width={props.width}
                        height={props.height}
                    />
                    <SubImageLink
                        position='double'
                        title={props.IsDesktop ? 'View our Signature Services Menu' : 'Signature Services Menu'}
                        page='services'
                    />
                    <SubImageLink
                        position='bottom'
                        title={props.IsDesktop ? 'View our Hair Extension Menu' : 'Hair Extension Menu' }
                        page='hairExtensions'
                    />
            </div>
            <div
                className='sub-image-landing-page2'
            >
                <img
                    src={aws_cloudfront_domain_name + "/homePage/LandingPageImage2.jpg"}
                    className='image-styling'
                    alt={aws_s3_domain_name + "/homePage/LandingPageImage2.jpg"}
                    width={props.width}
                    height={props.height}
                />
                <BookAppointmentBuyGiftCard/>
            </div>
            <div
                className='sub-image-landing-page3'
            >
                <img
                    src={aws_cloudfront_domain_name + "/homePage/LandingPageImage3.jpg"}
                    className='image-styling'
                    alt={aws_s3_domain_name + "/homePage/LandingPageImage3.jpg"}
                    width={props.width}
                    height={props.height}
                />
                <SubImageLink
                    position='double'
                    title={props.IsDesktop ? 'Read about our Luxury Brands' : 'Luxury Brands'}
                    page='brands'
                />
                <SubImageLink
                    position='bottom'
                    title='Meet our Artists'
                    page='team'
                />
            </div>
        </Stack>
        </div>
    )
}

export default function LandingPage() {   
    const info = extractHomepageInfo()
    return (
        <div className="landing-page-wrapper">
            <Header/>
                <div className='welcome-message-container-desktop'>
                    <Title className='title-header-landing-page' 
                        isDesktop={true}
                        titleInfo={info.title}
                    />
                    <Stack direction="row" spacing={1}>
                        <WelcomeContainer
                            className="welcome-message-landing-page"
                            welcomeMessageInfo={info.welcomeMessage}
                        />
                        <img
                            className='main-img-landing-page'
                            src={aws_cloudfront_domain_name + "/homePage/LandingPageImageMain.jpg"}
                            alt={aws_s3_domain_name + "/homePage/LandingPageImageMain.jpg"}
                            height={769}
                            width={544}
                        />
                    </Stack>
                </div>
                <div className='welcome-message-container-mobile'>
                    <Title
                        className='title-header-landing-page' 
                        isDesktop={false}
                        titleInfo={info.title}
                    />
                    <Title
                        className='title-header-landing-page-mini' 
                        isDesktop={false}
                        titleInfo={info.title}
                    />
                    <Stack direction="column" spacing={1}>
                        <WelcomeContainer
                            className="welcome-message-landing-page"
                            welcomeMessageInfo={info.welcomeMessage}
                        />
                        <div className='mid-size-landing-page-image'>
                            <div className='main-img-landing-page'>
                                <img
                                    src={aws_cloudfront_domain_name + "/homePage/LandingPageImageMain.jpg"}
                                    className='image-styling'
                                    alt={aws_s3_domain_name + "/homePage/LandingPageImageMain.jpg"}
                                    height={499}
                                    width={353}
                                />
                            </div>
                        </div>
                        <div className='smallest-landing-page-image'>
                            <div className='main-img-landing-page'>
                                <img
                                    src={aws_cloudfront_domain_name + "/homePage/LandingPageImageMain.jpg"}
                                    className='image-styling'
                                    alt={aws_s3_domain_name + "/homePage/LandingPageImageMain.jpg"}
                                    height={332}
                                    width={235}
                                />
                            </div>
                        </div>
                    </Stack>
                </div>
                <div className='sub-images-desktop'>
                    <SubImagesAndTheirLinks
                            height={362}
                            width={544}
                            isDesktop={true}
                        />
                </div>
                <div className='sub-images-tablet'>
                    <SubImagesAndTheirLinks
                            height={299}
                            width={449}
                            isDesktop={true}
                        />
                </div>
                <div className='sub-images-mobile'>
                    <SubImagesAndTheirLinks
                            height={235}
                            width={353}
                            isDesktop={false}
                        />
                </div>
            <Footer/>
        </div>
    )
}