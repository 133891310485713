import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import LandingPage from './components/landingPage/LandingPage';
import { Services } from './components/services/Services';
import { Brands } from './components/brands/Brands'
import { HairExtensions } from './components/hairExtensions/HairExtensions';
import { Artists } from './components/artists/Artists'
import Lightening from './components/services/readMorePages/Lightening';
import CustomColoring from './components/services/readMorePages/CustomColoring';
import Haircut from './components/services/readMorePages/Haircut';
import Canvas from './components/services/readMorePages/Canvas';
import Blowout from './components/services/readMorePages/Blowout';
import ProductOne from './components/brands/readMorePages/ProductOne';
import ProductTwo from './components/brands/readMorePages/ProductTwo';
import ExtensionConsultation from './components/hairExtensions/readMorePages/ExtensionConsultation';
import MaintenanceAppointments from './components/hairExtensions/readMorePages/MaintenanceAppointments';
import NewGuests from './components/hairExtensions/readMorePages/NewGuests';
import ArtistReadMorePage from './components/artists/ArtistReadMorePage';


function App() {
  return (
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/hairExtensions" element={<HairExtensions/>} />
          <Route path="/brands" element={<Brands/>} />
          <Route path="/team" element={<Artists/>} />
          {/* Read More pages */}
          <Route path="/lightening" element={<Lightening/>} />
          <Route path="/customColoring" element={<CustomColoring/>} />
          <Route path="/haircut" element={<Haircut/>} />
          <Route path="/canvas" element={<Canvas/>} />
          <Route path="/blowout" element={<Blowout/>} />
          <Route path="/ProductOne" element={<ProductOne/>} />
          <Route path="/ProductTwo" element={<ProductTwo/>} />
          <Route path="/extensionConsultation" element={<ExtensionConsultation/>} />
          <Route path="/maintenanceAppointments" element={<MaintenanceAppointments/>} />
          <Route path="/newGuests" element={<NewGuests/>} />
          <Route path="/artistPage" element={<ArtistReadMorePage/>} />
        </Routes>
      </div>
  );
}

export default App;
