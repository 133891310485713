import React from 'react';
import Stack from '@mui/material/Stack';
import Header from "../navigation/Header"
import Footer from "../footer/Footer"
import { ReadMore, aws_cloudfront_domain_name, GetS3CloudFrontJson } from '../../common/commonUtils';
import "./Brands.css"

function extractBrandsInfo() {
    const service = GetS3CloudFrontJson({filePath: "https://d2lk0s8zk8k1gx.cloudfront.net/brands.json"})

    var allInfo = {
        "title": "",
        "product1": {},
        "product2": {}
    }

    var title = ''
    var product1 = '{}'
    var product2 = '{}'

    for (let key in service) {
        if (key in allInfo) {
            allInfo[key] = service[key]
        }
    }

    if (allInfo["title"]) {
        title = allInfo["title"]
    }
    if (allInfo["product1"]) {
        product1 = JSON.stringify(allInfo["product1"])
    }
    if (allInfo["product2"]) {
        product2 = JSON.stringify(allInfo["product2"])
    }

    return {
                "title": title,
                "product1": product1,
                "product2": product2
            }
}

function BrandsTitle(props) {
    return (
        <div className='title'>
            { props.text }
        </div>
    )
}

function BrandHeader(props) {
    return (
        <div
            className='brand-header'
            style={{backgroundColor: props.backgroundColor}}
        >
            { props.title }
        </div>
    )
}

function ProductOneHeaderAndImages(props) {
    return (
        <div>
            <BrandHeader
                title={ props.title }
                backgroundColor='#577C8C'
            />
            <div className='stack-row-container-productOne'>
                <Stack direction='row'>
                    <img
                        className='productOne1-img'
                        src={aws_cloudfront_domain_name + "/brands/ProductOne1.png"}
                        alt=""
                    />
                    <img
                        className='productOne2-img'
                        src={aws_cloudfront_domain_name + "/brands/ProductOne2.png"}
                        alt=""
                    />
                </Stack>
            </div>
        </div>
    )
}

function ProductOneDescriptionBox(props) {
    const info = props.info ? JSON.parse(props.info) : {}
    return (
        <p class="productOne-text-box">
            <div className='product-header-text'>
                { info.header }
            </div>
            <br/>
            { info.paragraph1 }
            <br/><br/>
            { info.paragraph2 }
            <br/><br/>
            { info.paragraph3 }
            <br/><br/>
            { info.paragraph4 }
            <br/><br/>
            <ReadMore
                title='Shop Now'
                page={info.shopNowLink}
                isAbsolutePath={true}
                linkClassName='shop-now-brands'
            />
        </p>
    )
}

function ProductTwoDescriptionBox(props) {
    const info = props.info ? JSON.parse(props.info) : {}
    return (
        <p class="goldie-locks-text-box">
            <div className='product-header-text'>
            { info.header }
            </div>
            <br/>
            { info.paragraph1 }
            <br/><br/>
            <div className='bold'>
            { info.paragraph2 }
            <br/>
            { info.paragraph3 }
            </div>
            <br/><br/>
            { info.paragraph4 }
            <br/>
            { info.paragraph5 }
            <br/><br/>
            <ReadMore
                title='Shop Now'
                page={info.shopNowLink}
                isAbsolutePath={true}
                linkClassName='shop-now-brands'
            />
        </p>
    )
}

function ProductTwoHeaderAndImage(props) {
    return (
        <div>
            <BrandHeader
                title={props.title}
                backgroundColor='#B58547'
            />
            <div className='stack-row-container-goldie-locks'>
                <img
                    src={aws_cloudfront_domain_name + "/brands/ProductTwo1.png"}
                    className='goldie-locks-large1-img'
                    alt=""
                />
            </div>
        </div>
    )
}

function ProductTwoExtraImages() {
    return (
        <div className='stack-row-container-goldie-locks'>
            <Stack direction='row'>
                <img
                    className='goldie-locks1-img'
                    src={aws_cloudfront_domain_name + "/brands/ProductTwo3.png"}
                    alt=""
                />
                <img
                    className='goldie-locks2-img'
                    src={aws_cloudfront_domain_name + "/brands/ProductTwo4.png"}
                    alt=""
                />
            </Stack>
        </div>
    )
}

function ProductTwoHairRegrowthHeaderandImage(props) {
    const info = props.info ? JSON.parse(props.info) : {}
    return (
        <div>
            <p class="goldie-locks-text-box">
                <div className='product-header-text'>
                    { info.header }
                </div>
                <br/>
                    { info.paragraph1 }
            </p>
            <img
                src={aws_cloudfront_domain_name + "/brands/ProductTwo2.png"}
                className='goldie-locks-large2-img'
                alt=""
                style={{marginBottom: "50px"}}
            />
        </div>
    )
}

function DesktopView(props) {
    const product1 = props.brandsInfo.product1 ? JSON.parse(props.brandsInfo.product1) : {}
    const product2 = props.brandsInfo.product2 ? JSON.parse(props.brandsInfo.product2) : {}
    return (
        <div className='desktop-view-brands'>
            <BrandsTitle
                text={props.brandsInfo.title}
            />
            <ProductOneHeaderAndImages
                title={product1.title}
            />
            <ProductOneDescriptionBox
                info={JSON.stringify(product1.brandBox1)}
            />
            <ProductTwoHeaderAndImage
                title={product2.title}
            />
            <ProductTwoDescriptionBox
                info={JSON.stringify(product2.brandBox1)}
            />
            <ProductTwoExtraImages/>
            <ProductTwoHairRegrowthHeaderandImage
                info={JSON.stringify(product2.brandBox2)}
            />
        </div>
    )
}

function MobileView(props) {
    const product1 = props.brandsInfo.product1 ? JSON.parse(props.brandsInfo.product1) : {}
    const product2 = props.brandsInfo.product2 ? JSON.parse(props.brandsInfo.product2) : {}

    return (
        <div className='mobile-view-brands'>
            <BrandsTitle
                text={props.brandsInfo.title}
            />
            <ProductOneHeaderAndImages
                title={product1.title}
            />
            <p class="productOne-text-box-mobile">
                <ReadMore
                    className="read-more-brands"
                    linkClassName='read-more-link-brands'
                    title="Read more about BLEU"
                    page="productOne"
                />
                <ReadMore
                    title='Shop Now'
                    page={product1.brandBox1 ? product1.brandBox1.shopNowLink : ""}
                    isAbsolutePath={true}
                    linkClassName='shop-now-brands'
                />
            </p>
            <ProductTwoHeaderAndImage
                title={product1.title}
                width={358}
                height={199}
            />
            <p class="goldie-locks-text-box-mobile">
                <ReadMore
                    className="read-more-brands"
                    linkClassName='read-more-link-brands'
                    title="Read more about Goldie Locks"
                    page="productTwo"
                />
                <ReadMore
                    title='Shop Now'
                    page={product2.brandBox1 ? product2.brandBox1.shopNowLink : ""}
                    isAbsolutePath={true}
                    linkClassName='shop-now-brands'
                />
            </p>
        </div>
    )
}

function Brands() {
    const info = extractBrandsInfo()
    return (
        <div className='brands-container'>
            <Header/>
            <DesktopView
                brandsInfo={info}
            />
            <MobileView
                brandsInfo={info}
            />
            <Footer/>
        </div>
    )
}

export {
    Brands,
    BrandsTitle,
    ProductOneHeaderAndImages,
    ProductOneDescriptionBox,
    ProductTwoHeaderAndImage,
    ProductTwoDescriptionBox,
    ProductTwoExtraImages,
    ProductTwoHairRegrowthHeaderandImage,
    extractBrandsInfo
}