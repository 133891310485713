import { React } from 'react';
import Stack from '@mui/material/Stack';
import Header from "../navigation/Header"
import Footer from "../footer/Footer"
import { ReadMore, aws_cloudfront_domain_name, aws_s3_domain_name, GetS3CloudFrontJson } from '../../common/commonUtils';
import "./Artists.css"


function CHTextBox(props) {
    var header = <div/>
    var footer = <div/>
    if (props.head) {
        header = <div className='sub-header-text'>
                    { props.head }
                    <br/>
                </div>
    }
    if (props.tail) {
        footer = <div>
                    <br/><br/>
                    <div className='sub-header-text'>
                        { props.tail }
                    </div>
                </div>
    }
    return (
        <div className={props.className}>
            <p class="ch-text-box-paragraph">
                { header }
                { props.body.split('\n').map(str => <div><br/>{str}<br/></div>) }
                { footer }
            </p>
        </div>
    )
}

function SubTitle(props){
    return (
        <div className='artists-sub-title' style={{ display: "flex", alignItems: "center" }}>
            <p style={{ margin: "0 20px" }}>{ props.title }</p>
            <div style={{ flex: 1, backgroundColor: "#B1816D", height: "1px", marginTop: "15px" }} />
        </div>
    )
}

function ArtistProfile(props) {
    var subTitle = <div/>
    if (props.subTitle) {
        subTitle = <div className='staff-member-profile-subtitle'>
                       { props.subTitle.split('\n').map(str => <div>{str}</div>) }
                   </div>
    }
    let ig_handle_compenent = <div/>
    if (props.artist.ig_handle !== undefined) {
        ig_handle_compenent = <div className='artist-contact-info'>
                                  <a style={{ textDecoration: 'none', color: 'black' }} href={`https://www.instagram.com/${props.artist.ig_handle}/`}>@{props.artist.ig_handle}</a>
                              </div>
    }
    return (
        <div className='staff-member-container'>
            <img
                className={props.isOwnerProfile ? 'owner-image' : 'staff-image'}
                src={aws_cloudfront_domain_name + "/artists/" + props.artist.picture}
                alt={aws_s3_domain_name + "/artists/" + props.artist.picture}
            />
            <br/>
            <div className='artist-name'>
                {props.artist.name}
            </div>
            { subTitle }
            <br/>
            {
                ig_handle_compenent
            }
            <br/>
            <ReadMore
                title="Read more about me"
                page="artistPage"
                artistKey={props.artistKey}
            />
            <br/><br/>
        </div>
    )
}

function Owners(props) {

    const artists = GetS3CloudFrontJson({filePath: "https://d1tpjvssbtzmqj.cloudfront.net/owners.json"})
    var artistNames = new Array(artists.length).fill(null);
    for (let key in artists) {
        let priority = artists[key].priority - 1
        artistNames[priority] = key
    }
    
    return (
        <Stack direction={props.isDesktop ? "row" : "column"} className={props.isDesktop ? 'owner-staff-member-row-desktop' : 'owner-staff-member-row-mobile'}>
            {
                artistNames.map( (artistName) => (
                    <ArtistProfile
                        artist={{...artists[artistName]}}
                        artistKey={artistName}
                        subTitle={{...artists[artistName]}.subTitle}
                        isOwnerProfile={true}
                    />
                ))
            }
        </Stack>
    )
}


function Staff(props) {
    const artists = GetS3CloudFrontJson({filePath: "https://d1tpjvssbtzmqj.cloudfront.net/artists.json"})
    const staffRows = [];
    var desktopClassName = 'artist-staff-member-row-desktop-3'


    let curr_row = []
    for (let i = 0; i < artists.length; i += 1) {
        let artistProfile = <ArtistProfile
                                artist={artists[i]}
                                artistKey={`${i}`}
                                isOwnerProfile={false}
                            />
        if (curr_row.length < 3) {
            curr_row.push(
                artistProfile
            )
        } else {
            staffRows.push(
                <Stack direction={props.isDesktop ? "row" : "column"} className={props.isDesktop ? desktopClassName : 'artist-staff-member-row-mobile'}>
                    { curr_row } 
                </Stack>
            )
            curr_row = [artistProfile]
        }
    }
    if (curr_row.length > 0) {
        staffRows.push(
            <Stack direction={props.isDesktop ? "row" : "column"} className={curr_row.length < 3 ? 'artist-staff-member-row-desktop-less-than-3' : 'artist-staff-member-row-mobile'}>
                { curr_row } 
            </Stack>
        )
    }

    return (
        <div>
            {
                staffRows.map( (row) => (
                    <div>
                        { row }
                    </div>
                ))
            }
        </div>
    )
}

function ArtistsTitle() {
    return (
        <div className='artists-title'>
            Artists
        </div>
    )
}

function DesktopView() {
    return (
        <div className='desktop-view-artists'>
            <ArtistsTitle/>
            <SubTitle
                title='Meet Copper + Honey'
            />
            <Owners
                isDesktop={true}
            />
            <SubTitle
                title='Meet the Team'
            />
            <Staff
                isDesktop={true}
            />
        </div>
    )
}

function MobileView() {
    return (
        <div className='mobile-view-artists'>
            <ArtistsTitle/>
            <SubTitle
                title='Meet Copper + Honey'
            />
            <Owners
                isDesktop={false}
            />
            <SubTitle
                title='Meet the Team'
            />
            <Staff
                isDesktop={false}
            />
        </div>
    )
}

function Artists() {
    return (
        <div className='artists-container'>
            <Header/>
            <DesktopView/>
            <MobileView/>
            <Footer/>
        </div>
    )
}

export {
    Artists,
    ArtistsTitle,
    SubTitle,
    CHTextBox
}
