import React from 'react';
import Header from '../../navigation/Header';
import Footer from '../../footer/Footer';
import { BrandsTitle, ProductOneHeaderAndImages, ProductOneDescriptionBox, extractBrandsInfo } from '../Brands';
import { ReadLess } from '../../../common/commonUtils';
import "../Brands.css"

export default function ProductOne() {
    const info = extractBrandsInfo()
    const product = info.product1 ? JSON.parse(info.product1) : {}
    return (
        <div className='brands-container'>
            <Header/>
            <div className='mobile-view'>
                <BrandsTitle
                    text={info.title}
                />
                <ProductOneHeaderAndImages
                    title={product.title}
                />
                <ProductOneDescriptionBox
                    info={JSON.stringify(product.brandBox1)}
                />
                <ReadLess
                    page="brands"
                />
            </div>
            <Footer/>
        </div>
    )
}