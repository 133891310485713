import React from 'react';
import './Footer.css'
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import Stack from '@mui/material/Stack';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import companyInfo from "../../config/companyInfo.json";
import { aws_cloudfront_domain_name } from '../../common/commonUtils';

function getGoogleMapUrl() {
  var urlAddress = "https://www.google.com/maps/place/"
  for (var i = 0; i < companyInfo.physicalAddress.length; i++){
    urlAddress += companyInfo.physicalAddress[i].split(" ").join("+") + ",+"
  }
  return urlAddress.substring(0, urlAddress.length - 2)
}

function IconListShort() {
  return (
    <div class="short-list">
          <ul>
            <li>
              <a href={getGoogleMapUrl()}>
                <PlaceOutlinedIcon fontSize='large'/>
              </a>
            </li>
            <li>
              <a href={`tel:${companyInfo.phoneNumber}`}>
                <LocalPhoneOutlinedIcon fontSize='large'/>
              </a>
            </li>
            <li>
              <a href={`mailto:${companyInfo.email}`}>
                <EmailOutlinedIcon fontSize='large'/>
              </a>
            </li>
            <li>
                <a href={`https://www.instagram.com/${companyInfo.instagramHandle}/`}>
                  <InstagramIcon fontSize='large'/>
                </a>
            </li>
          </ul>
        </div>
  )
}

function IconListLong() {
  return (
    <div className='long-list'>
        <ul>
        <li className='long-address'>
            <Stack direction="row" spacing={2} >
            <div style={{
              display: 'flex',
              alignItems: 'center'
              }}>
              <div style={{
              marginRight: '15px'
              }}>
                <PlaceOutlinedIcon/>
              </div>
              <a href={getGoogleMapUrl()}>
                {
                  companyInfo.physicalAddress.map(
                    line=>
                      <div>
                        {line}<br/>
                      </div>
                    )
                }
              </a>
              </div>
            </Stack>
          </li>
          <li className='short-address'>
            <Stack direction="row" spacing={2} marginTop={1.5}>
              <PlaceOutlinedIcon/>
              <a href={getGoogleMapUrl()}>
                {
                  companyInfo.physicalAddress.at(
                    companyInfo.physicalAddress.length - 1
                  ).replace(/[0-9]/g, '').trim()
                }
              </a>
            </Stack>
          </li>
          <li>
            <Stack direction="row" spacing={2} marginTop={1.5}>
              <LocalPhoneOutlinedIcon
                height="100px"
              />
              <a href={`tel:${companyInfo.phoneNumber}`}>
                {
                  `(${companyInfo.phoneNumber.substring(0,3)}) 
                  ${companyInfo.phoneNumber.substring(4,7)}-
                  ${companyInfo.phoneNumber.substring(8,12)}`
                }
              </a>
            </Stack>
          </li>
          <li>
            <Stack direction="row" spacing={2} marginTop={1.5}>
              <EmailOutlinedIcon/>
              <a href={`mailto:${companyInfo.email}`}>Copperandhoneysalon@gmail.com</a>
              {/* <a href="mailto:copperandhoneysalon@gmail.com">Copperandhoneysalon@gmail.com</a> */}
            </Stack>
          </li>
          <li>
            <Stack direction="row" spacing={2} marginTop={1.5}>
              <InstagramIcon/>
              <a href={`https://www.instagram.com/${companyInfo.instagramHandle}/`}>@{companyInfo.instagramHandle}</a>
            </Stack>
          </li>
        </ul>
      </div>
  )
}

function CopyrightLogo() {
  return (
      <Stack direction="row" spacing={1}>
        <div className='copyright-hide'>
          Copyright
        </div>
        <CopyrightOutlinedIcon fontSize='small'/>
        <div className='copyright-hide'>
          {
            new Date().getFullYear()
          }
        </div>
        <div className='copyright-hide'>
          All rights reserved
        </div>
      </Stack>
  )
}

export default function Footer() {
  
    return (
      <footer className='footer'>
        <div className='large-footer-img'>
            <img src={aws_cloudfront_domain_name + "/logosAndIcons/ch_logo.png"}
              alt=""
              width={184}
              height={100}/>
          </div>
          <div className="small-footer-img">
            <img src={aws_cloudfront_domain_name + "/logosAndIcons/ch_logo.png"}
              alt=""
              width={92}
              height={50}/>
          </div>
        <div className='footer-container'>
          <IconListLong/>
          <IconListShort/>
        </div>
        <div className='right-logo'>
          <CopyrightLogo/>
        </div>
      </footer>
    )
  }