import React from 'react';
import Stack from '@mui/material/Stack';
import Header from "../navigation/Header"
import Footer from "../footer/Footer"
import { Link } from "react-router-dom";
import { ReadMore, GetS3CloudFrontJson } from '../../common/commonUtils';
import "./Services.css"

function extractServiceInfo() {
    const service = GetS3CloudFrontJson({filePath: "https://duo68zf6ox9z8.cloudfront.net/services.json"})

    var allInfo = {
        "lightening": {},
        "customColoring" : {},
        "canvas": {},
        "haircut": {},
        "blowout": {},
        "disclaimer": ""
    }

    var lighteningServiceInfo = ''
    var customColoringInfo = ''
    var canvasInfo = ''
    var haircutInfo = ''
    var blowoutInfo = ''
    var disclaimer = ''

    for (let key in service) {
        if (key in allInfo) {
            allInfo[key] = service[key]
        }
    }

    if (allInfo["lightening"]) {
        lighteningServiceInfo = JSON.stringify(allInfo["lightening"])
    }
    if (allInfo["customColoring"]) {
        customColoringInfo = JSON.stringify(allInfo["customColoring"])
    }
    if (allInfo["canvas"]) {
        canvasInfo = JSON.stringify(allInfo["canvas"])
    }
    if (allInfo["haircut"]) {
        haircutInfo = JSON.stringify(allInfo["haircut"])
    }
    if (allInfo["blowout"]) {
        blowoutInfo = JSON.stringify(allInfo["blowout"])
    }
    if (allInfo["disclaimer"]) {
        disclaimer = allInfo["disclaimer"]
    }

    return {
                "lighteningServiceInfo": lighteningServiceInfo,
                "customColoringInfo": customColoringInfo,
                "canvasInfo": canvasInfo,
                "haircutInfo": haircutInfo,
                "blowoutInfo": blowoutInfo,
                "disclaimer": disclaimer
            }
}

const parseServiceInfo = (props) => {
    var title = ''
    var intro = ''
    var services = []

    if (props.serviceInfo) {
        const parsedJson = JSON.parse(props.serviceInfo)
        title = parsedJson['title'] ? parsedJson['title'] : ''
        intro = parsedJson['intro'] ? parsedJson['intro'] : ''
        services = parsedJson['services'] ? parsedJson['services'] : []
    }

    return {
        "title": title,
        "intro": intro,
        "services": services
    }
}

const transformIntroTextToHtml = (props) => {
    var introTextBody = <div/>
    if (!props.hideDescriptions && props.intro) {
        introTextBody = 
            <p className='body-text'>
                { props.intro }
            </p>
    }
    return introTextBody
}

const transformBodyTextToHtml = (props) => {
    if (props.bodyText && props.bodyText.indexOf("<Link to=") !== -1) {
        var bodyText = props.bodyText
        var result = []
        var newBodyText = ""
        var i = 0
        var link = ""
        var linkText = ""
        while (i < bodyText.length) {
            if (bodyText[i] !== '<' && (bodyText.substring(i, i + "<Link to".length) !== "<Link to")) {
                newBodyText += bodyText[i]
                i += 1
            } else {
                bodyText = bodyText.substring(i + "<Link to=".length)
                link = bodyText.substring(0, bodyText.indexOf(">"))
                linkText = bodyText.substring(bodyText.indexOf(">") + 1, bodyText.indexOf("</Link>"))
                result.push(newBodyText)
                result.push(<Link className='click-here-link' to={"/" + link}> { linkText } </Link>)

                newBodyText = ""
                bodyText = bodyText.substring(bodyText.indexOf("</Link>") + "</Link>".length)
                i = 0
            }
        }
        result.push(newBodyText)
        return result
    }
    return [props.bodyText]
}

function SingleService(props) {
    return (
        <div className='lightening-single-service-container'>
            <div className='service-title'>
                {props.serviceTitle}
            </div>
            <div className='service-time'>
                {props.serviceTime}
            </div>
            <div className='description-body-text'>
                {props.hideDescriptions ? "" : props.description}
            </div>
        </div>
    )
}

function SingleCanvasService(props) {
    var descriptionBody = <div/>
    if (props.description[0] !== "") {
        descriptionBody = 
            <div className='add-padding-bottom'>
                <div className={props.hideDescriptions ? 'hide' : 'description-body-text'}>
                    { props.description }
                </div>
            </div>
    }
    return (
        <div>
        <div className={props.className}>
                <div className='canvas-service-title'>
                    {props.serviceTitle} 
                    <div className='canvas-service-time'>
                        {props.serviceTime}
                    </div>
                </div>
        </div>
        {
            descriptionBody
        }
        </div>
    )
}

function LighteningServiceBox(props) {
    const lighteningServiceInfo = parseServiceInfo({serviceInfo: props.lighteningServiceInfo})
    const introTextBody = transformIntroTextToHtml({hideDescriptions: props.hideDescriptions, intro: lighteningServiceInfo.intro})
    
    return (
        <div style={{whiteSpace: "pre-wrap"}}>
            <div className='title-text'>
                <div className='lightening-title-text'>
                    { lighteningServiceInfo.title }
                </div>
            </div>
            {
                introTextBody
            }
            { 
                lighteningServiceInfo.services.map( service => (
                    <SingleService
                        serviceTitle={service["serviceTitle"]}
                        serviceTime={service["serviceTime"]}
                        description={transformBodyTextToHtml({bodyText: service.bodyText})}
                        hideDescriptions={props.hideDescriptions}
                    />
                ))
            }
            <ReadMore
                page="lightening"
                hide={props.desktopView}
            />
        </div>
    )
}

function CustomColoringServiceBox(props) {
    const customColoringInfo = parseServiceInfo({serviceInfo: props.customColoringInfo})
    const introTextBody = transformIntroTextToHtml({hideDescriptions: props.hideDescriptions, intro: customColoringInfo.intro})

    return (
        <div>
            <div className='title-text'>
                <div className='custom-coloring-title-text'>
                    { customColoringInfo.title }
                </div>
            </div>
            {
                introTextBody
            }
            <p className='services-title-padding'>
            </p>
            { 
                customColoringInfo.services.map( service => (
                    <SingleService
                        serviceTitle={service["serviceTitle"]}
                        serviceTime={service["serviceTime"]}
                        description={transformBodyTextToHtml({bodyText: service.bodyText})}
                        hideDescriptions={props.hideDescriptions}
                    />
                ))
            }
            <ReadMore
                page="customColoring"
                hide={props.desktopView}
            />
        </div>
    )
}

function CanvasServiceBox(props) {
    const canvasInfo = parseServiceInfo({serviceInfo: props.canvasInfo})
    const introTextBody = transformIntroTextToHtml({hideDescriptions: props.hideDescriptions, intro: canvasInfo.intro})

    return (
        <div>
            <div className='title-text'>
                <div className='canvas-title-text'>
                    {canvasInfo.title}
                </div>
            </div>
            { 
                introTextBody
            }
            { 
                canvasInfo.services.map( service => (
                    <SingleCanvasService
                        serviceTitle={service["serviceTitle"]}
                        serviceTime={service["serviceTime"]}
                        description={transformBodyTextToHtml({bodyText: service.bodyText})}
                        hideDescriptions={props.hideDescriptions}
                    />
                ))
            }
            <p className={props.desktopView ? 'body-text' : ''}>
            </p>
            <ReadMore
                page="canvas"
                hide={props.desktopView}
            />
        </div>
    )
}

function HaircutServiceBox(props) {
    const haircutInfo = parseServiceInfo({serviceInfo: props.haircutInfo})
    const introTextBody = transformIntroTextToHtml({hideDescriptions: props.hideDescriptions, intro: haircutInfo.intro})

    return (
        <div>
            <div className='title-text'>
                <div className='haircut-title-text'>
                    { haircutInfo.title }
                </div>
            </div>
            <p className='services-title-padding'>
            </p>
            {
                introTextBody
            }
            { 
                haircutInfo.services.map( service => (
                    <SingleCanvasService
                        serviceTitle={service["serviceTitle"]}
                        serviceTime={service["serviceTime"]}
                        description={transformBodyTextToHtml({bodyText: service.bodyText})}
                        hideDescriptions={props.hideDescriptions}
                    />
                ))
            }
            <p className={props.desktopView ? "body-text" : "hide"}>
            </p>
            <ReadMore
                page="haircut"
                hide={props.desktopView}
            />
        </div>
    )
}

function BlowoutServiceBox(props) {
    const blowoutInfo = parseServiceInfo({serviceInfo: props.blowoutInfo})
    const introTextBody = transformIntroTextToHtml({hideDescriptions: props.hideDescriptions, intro: blowoutInfo.intro})

    return (
        <div>
            <div className='title-text'>
                <div className='blowout-title-text'>
                    { blowoutInfo.title }
                </div>
            </div>
            <p className='services-title-padding'>
            </p>
            {
                introTextBody
            }
            { 
                blowoutInfo.services.map( service => (
                    <SingleCanvasService
                        serviceTitle={service["serviceTitle"]}
                        serviceTime={service["serviceTime"]}
                        description={transformBodyTextToHtml({bodyText: service.bodyText})}
                        hideDescriptions={props.hideDescriptions}
                    />
                ))
            }
            <br className={props.desktopView ? 'hide' : ''}/>
            <ReadMore
                page="blowout"
                hide={props.desktopView}
            />
        </div>
    )
}

function Disclaimer(props) {
    return (
        <p className='disclaimer-text'>
            { props.bodyText }
        </p>
    )
}

function ServicesTitle() {
    return (
        <div>
            <div className='title-one'>
                Signature Services
            </div>
            <div className='title-two'>
                <Link className='click-here-link' to={"/hairExtensions"}>View our Hair Extensions Menu here</Link>
            </div>
        </div>
    )
}

function ColumnOne(props) {
    return (
        <Stack direction='column' className={props.className}>
            <LighteningServiceBox
                hideDescriptions={props.hideDescriptions}
                desktopView={props.desktopView}
                lighteningServiceInfo={props.serviceInfo.lighteningServiceInfo}
            />
            <CustomColoringServiceBox
                hideDescriptions={props.hideDescriptions}
                desktopView={props.desktopView}
                customColoringInfo={props.serviceInfo.customColoringInfo}
            />
        </Stack>
    )
}

function ColumnTwo(props) {
    return (
        <Stack direction='column' className={props.className}>
            <CanvasServiceBox
                hideDescriptions={props.hideDescriptions}
                desktopView={props.desktopView}
                canvasInfo={props.serviceInfo.canvasInfo}
            />
            <HaircutServiceBox
                hideDescriptions={props.hideDescriptions}
                desktopView={props.desktopView}
                haircutInfo={props.serviceInfo.haircutInfo}
            />
            <BlowoutServiceBox
                hideDescriptions={props.hideDescriptions}
                desktopView={props.desktopView}
                blowoutInfo={props.serviceInfo.blowoutInfo}
            />
            <Disclaimer
                bodyText={props.serviceInfo.disclaimer}
            />
        </Stack>
    )
}

function DesktopView(props) {
    return (
        <div className='desktop-view-services'>
            <ServicesTitle/>
            <div className='stack-row-container-services'>
                <Stack direction='row'>
                    <ColumnOne
                        className='first-column-desktop'
                        hideDescriptions={false}
                        desktopView={true}
                        serviceInfo={props.serviceInfo}
                    />
                    <ColumnTwo
                        className='second-column-desktop'
                        hideDescriptions={false}
                        desktopView={true}
                        serviceInfo={props.serviceInfo}
                    />
                </Stack>
            </div>
        </div>
    )
}

function MobileView(props) {
    return (
        <div className='mobile-view-services'>
            <ServicesTitle/>
            <div className='stack-row-container-services'>
                <Stack direction='column'>
                    <ColumnOne
                        hideDescriptions={true}
                        serviceInfo={props.serviceInfo}
                    />
                    <ColumnTwo
                        hideDescriptions={true}
                        serviceInfo={props.serviceInfo}
                    />
                </Stack>
            </div>
        </div>
    )
}

function Services() {
    const serviceInfo = extractServiceInfo()
    return (
        <div className='services-container'>
            <Header/>
            <DesktopView
                serviceInfo={serviceInfo}
            />
            <MobileView
                serviceInfo={serviceInfo}
            />
            <Footer/>
        </div>
    )
}

export {
    LighteningServiceBox,
    CustomColoringServiceBox,
    CanvasServiceBox,
    HaircutServiceBox,
    BlowoutServiceBox,
    Services,
    Disclaimer,
    ServicesTitle,
    extractServiceInfo
}