import React from 'react';
import Header from '../../navigation/Header';
import Footer from '../../footer/Footer';
import { NewGuestsBox, extractHairExtensionsInfo } from '../HairExtensions';
import { ReadLess } from '../../../common/commonUtils';
import "../HairExtensions.css"

export default function NewGuests() {
    const info = extractHairExtensionsInfo()
    return (
        <div className='brands-container'>
            <Header/>
            <div className='mobile-view-hair-extensions'>
                <NewGuestsBox
                    isDesktop={true}
                    textBody={info.section3}
                />
                <br/>
                <ReadLess
                    page="hairExtensions"
                />
            </div>
            <Footer/>
        </div>
    )
}